import * as React from "react"
import styled from 'styled-components/macro'
import Title from "../../common/Title";
import Subtitle from "../../common/Subtitle";
import Link from "../../common/Link"
import { keyframes } from "styled-components";
import {createRef, useEffect, useRef, useState} from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const LowFeeSectionWrapper = styled.div`
  width: 100%;
  color: #f9f9f9;
  padding-bottom: 100px;
  width: calc(50% - 50px);
  max-width: 500px;
  display: inline-block;
  vertical-align: top;
  text-align: left;
  margin-right: 25px;
  
  @media screen and (max-width: 1100px) {
    width: calc(50% - 80px);
  }
  
  @media screen and (max-width: 880px) {
    width: calc(100% - 40px);
    max-width: 440px;
    padding: 0 20px 100px;
    margin-right: 0;
  }
`

const Content = styled.div`
  width: 100%;
  padding-right: 25px;
  /*max-width: 990px;
  margin: 10px auto 0;*/
  
  @media screen and (max-width: 880px) {
    padding-right: 0;
  }
`

const ComingSoonText = styled.div`
  color: #606375;
  font-size: 15px;
  line-height: 16px;
  margin-top: 12px;
  display: inline-block;
  vertical-align: top;
`

// markup
const LowFeeSection = () => {
  const [clickedComingSoon, setClickedComingSoon] = useState(false)

  return (
    <LowFeeSectionWrapper>
      <div>
        <Content>
          <Title>
            {/*DEX margin trading*/}Trade without the high fees.
          </Title>
          <Subtitle>
            In order to allow for powerful DEX margin trading without the high gas fees, Dolomite is built on the most trusted and widely used Ethereum Layer 2 chains. This enables traders with smaller portfolios as well as high frequency traders to margin trade without gas fees cutting into your gains.
          </Subtitle>
          {clickedComingSoon ? <ComingSoonText>Coming very soon!</ComingSoonText> : <span onClick={() => setClickedComingSoon(true)}><Link text={'Learn more about how Dolomite uses Arbitrum\'s L2'} breakWidth={410} /></span>}
        </Content>
      </div>
    </LowFeeSectionWrapper>
  )
}

export default LowFeeSection
